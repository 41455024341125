import { forwardRef } from "react";
import PropTypes from "prop-types";
import { concatenateQueryParams } from "@gonoodle/gn-universe-utils";

import Link from "./Link";

const JSURL = require("jsurl");

const getDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || "Component";

function WithContentLink(WrappedComponent) {
  function Wrapper(props, ref) {
    const {
      contentLinkAsBase,
      contentLinkHrefBase,
      linkParams = {},
      className,
      events = [],
      ...rest
    } = props;
    const {
      sourceName,
      sourcePage,
      sourcePageType,
      sourceElement,
      ...params
    } = linkParams;
    return (
      <Link
        className={className}
        as={`${contentLinkAsBase}/${rest.id}/${rest.slug}`}
        href={concatenateQueryParams(contentLinkHrefBase, {
          referrer: JSURL.stringify({
            sourceName,
            sourcePage,
            sourcePageType,
            sourceElement,
          }),
          ...params,
        })}
        events={events}
      >
        <WrappedComponent {...rest} ref={ref} />
      </Link>
    );
  }

  Wrapper.displayName = `WithContentLink(${getDisplayName(WrappedComponent)})`;
  const WrapperWithRef = forwardRef(Wrapper);

  Wrapper.propTypes = {
    className: PropTypes.string,
    contentLinkAsBase: PropTypes.string.isRequired,
    contentLinkHrefBase: PropTypes.string.isRequired,
    linkParams: PropTypes.shape({
      sourceId: PropTypes.string,
      sourceName: PropTypes.string,
      sourcePage: PropTypes.string,
      sourcePageType: PropTypes.string,
      sourceElement: PropTypes.string,
    }),
    events: PropTypes.arrayOf(
      PropTypes.shape({
        event: PropTypes.string.isRequired,
        properties: PropTypes.shape({}).isRequired,
      }),
    ),
  };

  return WrapperWithRef;
}

export default WithContentLink;
